import React, {Component} from 'react'
import clamp from 'lodash/clamp'
import {route} from 'router'
import {Query} from 'react-apollo'
import {name as formatName} from 'utils/format'
import { gql } from 'graphql.macro'

import './Search.scss'

export const GET_SEARCH_DEVICES = gql`
  query GET_SEARCH_DEVICES($query: String) {
    devicesRe(query: $query) {
      id
      name
      path
    }
  }
`

const GET_USERS = gql`
  query GET_USERS {
    usersRe {
      id
      firstName
      lastName
    }
  }
`

class Search extends Component {
  state = {query: '', showing: false}
  keyDownHandler = e => {
    const keyCode = e.keyCode || e.which
    //esc
    if (keyCode === 27) {
      e.stopPropagation()
      this.setState({showing: !this.state.showing})
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownHandler, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler, false)
  }
  clickAway = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({showing: false})
  }
  render() {
    if (this.state.showing)
      return (
        <Query query={GET_SEARCH_DEVICES} variables={{query: 'search'}}>
          {({data}) => (
            <Query query={GET_USERS}>
              {({data: dataUsers}) => {
                const q = this.state.query.toLowerCase()
                let results =
                  q * 1 ? [{type: 'device', value: {id: q * 1}}] : [] // if query is a number, put the device as a result]
                if (this.state.query.length) {
                  const devices = (
                    (data && data.devicesRe) ||
                    []
                  ).filter(
                    d =>
                      (d.name || '').toLowerCase().includes(q) ||
                      (d.path || '').toLowerCase().includes(q)
                  )
                  const users = ((dataUsers && dataUsers.usersRe) || []).filter(
                    u =>
                      `${u.firstName} ${u.lastName}`.toLowerCase().includes(q)
                  )

                  results = results.concat([
                    ...users.slice(0, 5).map(u => ({type: 'user', value: u})),
                    ...devices
                      .slice(0, 5)
                      .map(d => ({type: 'device', value: d}))
                  ])
                }
                return (
                  <div className="global-search" onClick={this.clickAway}>
                    <div className="window" onClick={e => e.stopPropagation()}>
                      <div className="query">
                        <i className="fa fa-search" />
                        <Input
                          value={this.state.query}
                          onChange={e => this.setState({query: e.target.value})}
                        />
                      </div>
                      <SearchResults
                        results={results}
                        close={() => this.setState({showing: false})}
                      />
                    </div>
                  </div>
                )
              }}
            </Query>
          )}
        </Query>
      )
    else return null
  }
}

class SearchResults extends React.Component {
  state = {index: 0}
  componentDidMount() {
    document.addEventListener('keydown', this.keyDownHandler, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler, false)
  }
  keyDownHandler = e => {
    const keyCode = e.keyCode || e.which
    //up
    if (keyCode === 38) {
      e.stopPropagation()
      e.preventDefault()
      this.setState(({index, results}) => ({
        index: clamp(index - 1, 0, this.props.results.length - 1)
      }))
    }
    //down
    if (keyCode === 40) {
      e.stopPropagation()
      e.preventDefault()
      this.setState(({index, results}) => ({
        index: clamp(index + 1, 0, this.props.results.length - 1)
      }))
    }
    //enter
    if (keyCode === 13) {
      e.stopPropagation()
      e.preventDefault()
      if (this.props.results[this.state.index])
        this.goToResult(this.props.results[this.state.index])
    }
  }

  goToResult = ({type, value}) => {
    this.props.close()
    route(type === 'device' ? `/devices/${value.id}` : `/team/user/${value.id}`)
  }
  render() {
    const results = this.props.results
    return (
      <div className="results">
        <ul>
          {results.map((r, i) => (
            <li
              key={i}
              className={
                this.state.index === i ? 'clickable selected' : 'clickable'
              }
              onClick={() => this.goToResult(r)}
            >
              {r.type === 'device' ? (
                <span>
                  [{r.value.id}] {r.value.path} :{' '}
                  <strong>{r.value.name}</strong>
                </span>
              ) : (
                <strong>{formatName(r.value, {firstLast: false})}</strong>
              )}
            </li>
          ))}
        </ul>
        {results.length === 0 && this.state.query === '' && (
          <ul>
            <li className="search-help-text">
              <em>Search for a device name, directory, or ID.</em>
            </li>
          </ul>
        )}
      </div>
    )
  }
}

class Input extends Component {
  componentDidMount() {
    if (this.ref) this.ref.select()
  }
  render() {
    return (
      <input
        value={this.props.value}
        onChange={this.props.onChange}
        ref={ref => (this.ref = ref)}
      />
    )
  }
}
export default Search
