import React from 'react'
import {linkHandler} from 'router'

import './index.scss'

export default () => null

export const Menu = () => (
  <div className="admin-menu">
    <ul>
      <li className="clickable" onClick={linkHandler('/admin/newPO')}>
      <i className="fa-solid fa-tablet"></i> <span>New P.O.</span>
      </li>
      <li className="clickable" onClick={linkHandler('/admin/stickers')}>
      <i className="fa-solid fa-print"></i> <span>Stickers</span>
      </li>
      <li className="clickable" onClick={linkHandler('/admin/deviceIDs')}>
      <i className="fa-solid fa-table-list"></i> <span>Device IDs</span>
      </li>
      <li className="clickable" onClick={linkHandler('/admin/billing')}>
      <i className="fa-solid fa-receipt"></i> <span>Billing</span>
      </li>
      <li className="clickable" onClick={linkHandler('/admin/messageLog')}>
      <i className="fa-solid fa-book-open"></i> <span>Message Log</span>
      </li>
      <li className="clickable" onClick={linkHandler('/admin/fota')}>
      <i className="fa-solid fa-file-export"></i> <span>FOTA Management</span>
      </li>
      <li className="clickable" onClick={linkHandler('/manager/update')}>
      <i className="fa fa-sliders"/> <span>Update Devices</span>
      </li>
      <li className="clickable" onClick={linkHandler('/manager/alarmsSet')}>
      <i className="fa fa-tag"/> <span>Check Alarms Set</span>
      </li>
    </ul>
  </div>
)
