import React from 'react'
import ago from 'utils/ago'

import './List.scss'

class List extends React.Component {
  state = {
    sortBy: this.props.on,
    sortDirection: this.props.direction,
    menu: {
      type: false
    }
  }
  sort = (key) => (e) => {
    e.preventDefault()
    this.setState((state) => ({
      sortBy: key,
      sortDirection:
        state.sortBy === key
          ? state.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc'
    }))
  }

  renderTree = (tag, subtype1, subtype2) => {
    let tagString = tag
    let subString1 = subtype1
    let subString2 = subtype2
    switch (tag) {
      case 'spsr':
        tagString = 'Tank Level'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'dspsr':
        tagString = 'Tank Level - Dual Sensor'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'psr':
        tagString = 'Pressure'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Normal'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'puls':
        tagString = 'Turbine'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'rot':
        tagString = 'Beam Pump'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          default:
            subString1 = 'Dongle - Not Reported'
        }
        subString2 = 'na'
        break
      case 'temp':
        tagString = 'Temperature'
        switch (subtype1) {
          case 1:
            subString1 = 'Skin'
            break
          case 2:
            subString1 = 'Insert'
            break
          default:
            subString1 = 'Not Reported'
        }
        switch (subtype2) {
          case 1:
            subString2 = 'Bare Wire'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'vib':
        tagString = 'Vibration'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          case 3:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'dist':
        tagString = 'Radar'
        switch (subtype1) {
          case 1:
            subString1 = 'Internal'
            break
          case 2:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'hum':
        tagString = 'Humidity'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'sonr':
        tagString = 'Sonar'
        subString1 = 'na'
        subString2 = 'na'
        break
      default:
        tagString = 'na'
        subString1 = 'na'
        subString2 = 'na'
        break
    }
    return {tagString, subString1, subString2}
  }
  sortIndicator = (key) =>
    this.state.sortBy === key ? (
      this.state.sortDirection === 'asc' ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      )
    ) : null
  get sortFunction() {
    const reverse = this.state.sortDirection === 'asc'
    switch (this.state.sortBy) {
      case 'id':
        return (a, b) => (reverse ? a.id - b.id : b.id - a.id)
      case 'uaid':
        return (a, b) => (reverse ? a.userAssignedID - b.userAssignedID : b.userAssignedID - a.userAssignedID)
      case 'name':
        return (a, b) =>
          (reverse ? 1 : -1) *
          ((a.path || 'zzzzz') + (a.name || 'zzzzz')).localeCompare(
            (b.path || 'zzzzz') + (b.name || 'zzzzz')
          )
      case 'companyName':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.companyName || 'zzzzz').localeCompare((b.companyName || 'zzzzz'))
      case 'battery':
        return (a, b) =>
          !a.batteryLevel
            ? 1
            : !b.batteryLevel
            ? -1
            : (reverse ? 1 : -1) * (a.batteryLevel - b.batteryLevel)
      case 'time':
        return (a, b) =>
          !a.lastTimeMessageReceived
            ? 1
            : !b.lastTimeMessageReceived
            ? -1
            : (reverse ? -1 : 1) * (a.lastTimeMessageReceived - b.lastTimeMessageReceived)
      case 'defaultTagHumanReadable':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.defaultTagHumanReadable || 'zzzzz').localeCompare(b.defaultTagHumanReadable || 'zzzzz')
      case 'signalStrength':
        return (a, b) => (reverse ? a.signalStrength - b.signalStrength : b.signalStrength - a.signalStrength)
      case 'boardRev':
        return (a, b) =>
          (reverse ? 1 : -1) *
          ((a.boardRev || 'zzzzz') + (a.boardRev || 'zzzzz')).localeCompare(
            (b.boardRev || 'zzzzz') + (b.boardRev || 'zzzzz')
          )
      case 'firmwareVersion':
        return (a, b) => (reverse ? a.firmwareVersion - b.firmwareVersion : b.firmwareVersion - a.firmwareVersion)
      case 'units':
        return (a, b) =>
        (reverse ? 1 : -1) *
        (a.displayUnits || 'zzzzz').localeCompare((b.displayUnits || 'zzzzz'))
      case 'coordslo':
        return (a, b) => (reverse ? a.longitude - b.longitude : b.longitude - a.longitude)
      case 'coordsla':
        return (a, b) => (reverse ? a.latitude - b.latitude : b.latitude - a.latitude)
      case 'reading':
        return (a, b) => (reverse ? a.latestReading - b.latestReading : b.latestReading - a.latestReading)
      case 'modemType':
        return (a, b) => (reverse ? a.modemType - b.modemType : b.modemType - a.modemType)
      case 'simNumber':
        return (a, b) => (reverse ? a.simNumber - b.simNumber : b.simNumber - a.simNumber)
      case 'status':
        return (a, b) => (reverse ? a.isActive - b.isActive : b.isActive - a.isActive)
      case 'percentSolar':
        return (a, b) => (reverse ? a.percentSolar - b.percentSolar : b.percentSolar - a.percentSolar)
      case 'batteryDelta':
        return (a, b) => (reverse ? a.batteryDelta - b.batteryDelta : b.batteryDelta - a.batteryDelta)
      case 'timelog':
        return (a, b) => (reverse ? a.time - b.time : b.time - a.time)
      case 'gpslog':
        return (a, b) => (reverse ? a.gps_time - b.gps_time : b.gps_time - a.gps_time)
      case 'count':
        return (a, b) => (reverse ? a.count - b.count : b.count - a.count)
      default:
        return undefined
    }
  }
  render() {
    var devices = []
    devices = this.props.devices
      .map(
        ({
          id,
          userAssignedID,
          name,
          path,
          isActive,
          lastTimeMessageReceived,
          latestReading,
          batteryLevel,
          company,
          displayUnits,
          defaultTag,
          longitude,
          latitude,
          sensorSubtype1,
          sensorSubtype2,
          firmwareVersion,
          modemType,
          simNumber,
          boardRev,
          signalStrength,
          percentSolar,
          batteryDelta,
          time,
          gps_time,
          count
        }) => {
          const tree = this.renderTree(
            defaultTag,
            sensorSubtype1,
            sensorSubtype2
          )
          
          const defaultTagHumanReadable = tree.tagString
          sensorSubtype1 = tree.subString1
          sensorSubtype2 = tree.subString2
          
          return {
            id,
            userAssignedID,
            name,
            companyName: company && company.name,
            path,
            isActive,
            lastTimeMessageReceived,
            latestReading,
            batteryLevel,
            displayUnits,
            defaultTag,
            longitude,
            latitude,
            defaultTagHumanReadable,
            sensorSubtype1,
            sensorSubtype2,
            firmwareVersion,
            modemType,
            simNumber,
            boardRev,
            signalStrength,
            percentSolar,
            batteryDelta,
            time,
            gps_time,
            count
          }
        }
      )
      .sort(this.sortFunction)

      if (this.props?.type === 'alerts') {
        return (
          <div className="list-container device-list">
            <div className="scrollable-container">
              <table>
                <thead>
                  <tr>
                  <th className="id clickable" onClick={this.sort('id')}>
                    ID {this.sortIndicator('id')}
                  </th>
                  {(!this.props.isSuperUser && (this.props.type !== 'cell')) && (
                  <th className="id clickable" onClick={this.sort('uaid')}>
                    UA ID {this.sortIndicator('uaid')}
                  </th>
                  )}
                  <th className="name clickable" onClick={this.sort('name')}>
                    Name {this.sortIndicator('name')}
                  </th>
                  <th className="last-message clickable" onClick={this.sort('companyName')}>
                    Company {this.sortIndicator('companyName')}
                  </th>
                  <th className="last-message clickable" onClick={this.sort('status')}>
                    Status {this.sortIndicator('status')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={this.sort('defaultTagHumanReadable')}
                  >
                    Sensor Type {this.sortIndicator('defaultTagHumanReadable')}
                  </th>
                  {!this.props.isSuperUser && (
                  <>
                    <th
                      className="last-message clickable"
                      onClick={ this.sort('coordsla')}
                    >
                      Coords {this.sortIndicator('coordsla')}
                    </th>
                    <th
                      className="last-message clickable"
                      onClick={this.sort('units')}
                    >
                      Units {this.sortIndicator('units')}
                    </th>
                    <th
                      className="last-message clickable"
                      onClick={ this.sort('reading')}
                    >
                      Last Reading {this.sortIndicator('reading')}
                    </th>
                  </>
                  )}
                  <th className="last-message clickable" onClick={ this.sort('time')}>
                    Last Msg. {this.sortIndicator('time')}
                  </th>
                  <th className="voltage clickable" onClick={ this.sort('battery')}>
                    Battery {this.sortIndicator('battery')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={ this.sort('firmwareVersion')}
                  >
                    Firmware {this.sortIndicator('firmwareVersion')}
                  </th>
                  {this.props.isSuperUser && (
                    <>
                      <th
                        className="last-message clickable"
                        onClick={ this.sort('signalStrength')}
                      >
                        Signal {this.sortIndicator('signalStrength')}
                      </th>
                      <th
                        className="last-message clickable"
                        onClick={ this.sort('modemType')}
                      >
                        Modem {this.sortIndicator('modemType')}
                      </th>
                      <th
                        className="sim-number clickable"
                        onClick={ this.sort('simNumber')}
                      >
                        SIM Number {this.sortIndicator('simNumber')}
                      </th>
                      <th
                        className="last-message clickable"
                        onClick={ this.sort('boardRev')}
                      >
                        Board {this.sortIndicator('boardRev')}
                      </th>
                    </>
                  )}
                </tr>
                </thead>
                <tbody>
                  {devices.map((d) => (
                    <tr key={d.id} className="row" style={{height: 30}}>
                      <td className="id" style={{height: 'auto'}}>
                        <a href={`#/devices/${d.id}`}>{d.id}</a>
                      </td>
                      {!this.props.isSuperUser && (
                      <td
                        className="id"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.userAssignedID? d.userAssignedID : <i>N/A</i>}
                      </td>
                      )}
                      <td
                        className="name"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <a href={`#/devices/${d.id}`}>
                          {d.path ? d.path + ' : ' : !d.name ? <i>N/A</i> : ''} <strong>{d.name ? d.name : !d.path ? '' :'[No Name]'}</strong>
                        </a>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.companyName || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.isActive ? 'Active' : 'Inactive'}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.defaultTagHumanReadable && d.defaultTagHumanReadable !== 'na' ?  d.defaultTagHumanReadable : <i>N/A</i>}
                      </td>
                      {!this.props.isSuperUser && (
                      <>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                          {(d.latitude && d.longitude) ? `${d.latitude}, ${d.longitude}` : <i>N/A</i>}
                        </td>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                          {d.displayUnits ? d.displayUnits : 'default'}
                        </td>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                          {d.latestReading ? d.latestReading : <i>N/A</i>}
                        </td>
                      </>
                      )}
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isDelayed ? 'warning' : 'normal'}>
                          {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                        </div>
                      </td>
                      <td
                        className="voltage"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                          {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : <i>N/A</i>}
                        </div>
                      </td>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                          {d.firmwareVersion || <i>N/A</i>}
                        </td>
                      {this.props.isSuperUser && (
                        <>
                          <td
                            className="last-message"
                            style={{height: 'auto', minHeight: '1.1em'}}
                          >
                            {d.signalStrength || <i>N/A</i>}
                          </td>
                          <td
                            className="last-message"
                            style={{height: 'auto', minHeight: '1.1em'}}
                          >
                          {d.modemType || <i>N/A</i>}
                          </td>
                          <td
                            className="sim-number"
                            style={{height: 'auto', minHeight: '1.1em'}}
                          >
                          {d.simNumber || <i>N/A</i>}
                          </td>
                          <td
                            className="last-message"
                            style={{height: 'auto', minHeight: '1.1em'}}
                          >
                          {d.boardRev || <i>N/A</i>}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      if (this.props?.type === 'battery') {
        return (
          <div className="list-container device-list">
            <div className="scrollable-container">
              <table>
                <thead>
                  <tr>
                  <th className="id clickable" onClick={this.sort('id')}>
                    ID {this.sortIndicator('id')}
                  </th>
                  <th className="name clickable" onClick={this.sort('name')}>
                    Name {this.sortIndicator('name')}
                  </th>
                  <th className="last-message clickable" onClick={this.sort('companyName')}>
                    Company {this.sortIndicator('companyName')}
                  </th>
                  <th className="last-message clickable" onClick={ this.sort('time')}>
                    Last Msg. {this.sortIndicator('time')}
                  </th>
                  <th className="voltage clickable" onClick={ this.sort('battery')}>
                    Battery {this.sortIndicator('battery')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={ this.sort('firmwareVersion')}
                  >
                    Firmware {this.sortIndicator('firmwareVersion')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={ this.sort('signalStrength')}
                  >
                    Signal {this.sortIndicator('signalStrength')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={this.sort('percentSolar')}
                  >
                    Percent Solar {this.sortIndicator('percentSolar')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={this.sort('batteryDelta')}
                  >
                    Battery Delta {this.sortIndicator('batteryDelta')}
                  </th>
                </tr>
                </thead>
                <tbody>
                  {devices.map((d) => (
                    <tr key={d.id} className="row" style={{height: 30}}>
                      <td className="id" style={{height: 'auto'}}>
                        <a href={`#/devices/${d.id}`}>{d.id}</a>
                      </td>
                      <td
                        className="name"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <a href={`#/devices/${d.id}`}>
                          {d.path ? d.path + ' : ' : !d.name ? <i>N/A</i> : ''} <strong>{d.name ? d.name : !d.path ? '' :'[No Name]'}</strong>
                        </a>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.companyName || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isDelayed ? 'warning' : 'normal'}>
                          {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                        </div>
                      </td>
                      <td
                        className="voltage"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                          {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : <i>N/A</i>}
                        </div>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.firmwareVersion || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.signalStrength || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.percentSolar ? `${(d.percentSolar / 10).toFixed(1)} %` : '0 %'}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.batteryDelta ? `${(d.batteryDelta / 1000).toFixed(3)} MV` : '0.000 MV'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      if (this.props?.type === 'batteryCompact') {
        return (
          <div className="list-container device-list">
            <div className="scrollable-container-unique">
              <table>
              <thead>
                <tr>
                  <th className="id clickable" onClick={this.sort('id')}>
                  ID {this.sortIndicator('id')}
                  </th>
                  <th className="name clickable" onClick={this.sort('name')}>
                    Name {this.sortIndicator('name')}
                  </th>
                  <th className="voltage clickable" onClick={this.sort('battery')}>
                    Battery {this.sortIndicator('battery')}
                  </th>
                </tr>
              </thead>
                <tbody>
                  {devices.map((d) => (
                    <tr key={d.id} className="row" style={{height: 30}}>
                        <td className="id" style={{height: 'auto'}}>
                        <a href={`#/devices/${d.id}`}>{d.id}</a>
                      </td>
                      <td
                        className="name"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <a href={`#/devices/${d.id}`}>
                          <strong>{d.name ? d.name : <i>N/A</i>}</strong>
                        </a>
                      </td>
                      <td
                        className="voltage"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                          {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : <i>N/A</i>}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      if (this.props?.type === 'alertCompact') {
        return (
          <div className="list-container device-list">
            <div className="scrollable-container-unique">
              <table>
              <thead>
                <tr>
                  <th className="id clickable" onClick={this.sort('id')}>
                    ID {this.sortIndicator('id')}
                  </th>
                  <th className="name clickable" onClick={this.sort('name')}>
                    Name {this.sortIndicator('name')}
                  </th>
                  <th className="last-message clickable" onClick={this.sort('time')}>
                    Last Message {this.sortIndicator('time')}
                  </th>
                </tr>
              </thead>
                <tbody>
                  {devices.map((d) => (
                    <tr key={d.id} className="row" style={{height: 30}}>
                        <td className="id" style={{height: 'auto'}}>
                        <a href={`#/devices/${d.id}`}>{d.id}</a>
                      </td>
                      <td
                        className="name"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <a href={`#/devices/${d.id}`}>
                          <strong>{d.name ? d.name : <i>N/A</i>}</strong>
                        </a>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div className={d.isDelayed ? 'warning' : 'normal'}>
                          {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      if (this.props?.type === 'reboot') {
        return (
          <div className="list-container device-list">
            <div className="scrollable-container">
              <table>
              <thead>
                <tr>
                  <th className="id clickable" onClick={this.sort('id')}>
                    ID {this.sortIndicator('id')}
                  </th>
                  <th className="id clickable" onClick={this.sort('timelog')}>
                    Date First Logged {this.sortIndicator('timelog')}
                  </th>
                  <th className="id clickable" onClick={this.sort('gpslog')}>
                    GPS Reading Time {this.sortIndicator('gpslog')}
                  </th>                  
                  <th className="id clickable" onClick={this.sort('count')}>
                    Reboot Count {this.sortIndicator('count')}
                  </th>
                </tr>
              </thead>
                <tbody>
                  {devices.map((d) => (
                    <tr key={d.id} className="row" style={{height: 30}}>
                        <td className="last-message" style={{height: 'auto'}}>
                        <a href={`#/devices/${d.id}`}>{d.id}</a>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div>
                          {d.time ? dateConvert(d.time) : <i>N/A</i>}
                        </div>
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div>
                          {d.gps_time ? dateConvert(d.gps_time) : <i>N/A</i>}
                        </div>
                      </td>                      
                      <td
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        <div>
                          {d.count ? d.count >= 10 ? <div style={{color: 'red'}}>{d.count}</div> : d.count : <i>N/A</i>}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      return (
        <div className="list-container device-list">
          <div className="scrollable-container">
            <table>
              <thead>
                <tr>
                <th className="id clickable" onClick={this.sort('id')}>
                  ID {this.sortIndicator('id')}
                </th>
                {(!this.props.isSuperUser) && (
                <th className="id clickable" onClick={this.sort('uaid')}>
                  UA ID {this.sortIndicator('uaid')}
                </th>
                )}
                <th className="name clickable" onClick={this.sort('name')}>
                  Name {this.sortIndicator('name')}
                </th>
                <th className="last-message clickable" onClick={this.sort('companyName')}>
                  Company {this.sortIndicator('companyName')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={this.sort('defaultTagHumanReadable')}
                >
                  Sensor Type {this.sortIndicator('defaultTagHumanReadable')}
                </th>
                {!this.props.isSuperUser && (
                <>
                  <th
                    className="last-message clickable"
                    onClick={ this.sort('coordsla')}
                  >
                    Coords {this.sortIndicator('coordsla')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={this.sort('units')}
                  >
                    Units {this.sortIndicator('units')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={ this.sort('reading')}
                  >
                    Last Reading {this.sortIndicator('reading')}
                  </th>
                </>
                )}
                <th className="last-message clickable" onClick={ this.sort('time')}>
                  Last Msg. {this.sortIndicator('time')}
                </th>
                <th className="voltage clickable" onClick={ this.sort('battery')}>
                  Battery {this.sortIndicator('battery')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={ this.sort('firmwareVersion')}
                >
                  Firmware {this.sortIndicator('firmwareVersion')}
                </th>
                {this.props.isSuperUser && (
                  <>
                    <th
                      className="last-message clickable"
                      onClick={ this.sort('signalStrength')}
                    >
                      Signal {this.sortIndicator('signalStrength')}
                    </th>
                    <th
                      className="last-message clickable"
                      onClick={ this.sort('modemType')}
                    >
                      Modem {this.sortIndicator('modemType')}
                    </th>
                    <th
                      className="sim-number clickable"
                      onClick={ this.sort('simNumber')}
                    >
                      SIM Number {this.sortIndicator('simNumber')}
                    </th>
                    <th
                      className="last-message clickable"
                      onClick={ this.sort('boardRev')}
                    >
                      Board {this.sortIndicator('boardRev')}
                    </th>
                  </>
                )}
              </tr>
              </thead>
              <tbody>
                {devices.map((d) => (
                  <tr key={d.id} className="row" style={{height: 30}}>
                    <td className="id" style={{height: 'auto'}}>
                      <a href={`#/devices/${d.id}`}>{d.id}</a>
                    </td>
                    {!this.props.isSuperUser && (
                    <td
                      className="id"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.userAssignedID? d.userAssignedID : <i>N/A</i>}
                    </td>
                    )}
                    <td
                      className="name"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      <a href={`#/devices/${d.id}`}>
                        {d.path ? d.path + ' : ' : !d.name ? <i>N/A</i> : ''} <strong>{d.name ? d.name : !d.path ? '' :'[No Name]'}</strong>
                      </a>
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.companyName || <i>N/A</i>}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.defaultTagHumanReadable && d.defaultTagHumanReadable !== 'na' ?  d.defaultTagHumanReadable : <i>N/A</i>}
                    </td>
                    {!this.props.isSuperUser && (
                    <>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {(d.latitude && d.longitude) ? `${d.latitude}, ${d.longitude}` : <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.displayUnits ? d.displayUnits : 'default'}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.latestReading ? d.latestReading : <i>N/A</i>}
                      </td>
                    </>
                    )}
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      <div className={d.isDelayed ? 'warning' : 'normal'}>
                        {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                      </div>
                    </td>
                    <td
                      className="voltage"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                        {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : <i>N/A</i>}
                      </div>
                    </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.firmwareVersion || <i>N/A</i>}
                      </td>
                    {this.props.isSuperUser && (
                      <>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                          {d.signalStrength || <i>N/A</i>}
                        </td>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                        {d.modemType || <i>N/A</i>}
                        </td>
                        <td
                          className="sim-number"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                        {d.simNumber || <i>N/A</i>}
                        </td>
                        <td
                          className="last-message"
                          style={{height: 'auto', minHeight: '1.1em'}}
                        >
                        {d.boardRev || <i>N/A</i>}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
  }
}

const dateConvert = (date) => {
  const obj = new Date(date * 1000)
  return obj.toLocaleDateString()
}

export default List
