import React, { useState } from "react"
import "./Dropdown.scss"

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  return (
    <div className="dropdown-container">
      <div className="guide" onClick={toggleDropdown}>
      <i className='fa fa-book-open fa-lg'/>
      </div>
      {isOpen && (
        <div className="dropdown-menu" onMouseLeave={closeDropdown}>
          <a href="https://www.notion.so/Sensorfield-Dashboard-Walk-through-c8851b75cbc24f8782b6c20b2f1c3ccc" target="_blank" rel="noopener noreferrer" className="dropdown-item">Notion Documentation</a>
          <a href="https://docs.google.com/document/d/1_B1Z7MbrC96vuODDh9IsfLf6p_wl_XNnX7wQkYW0tEM/edit?tab=t.6bncxdjw1hly#heading=h.vwhtrlhtc5t2" target="_blank" rel="noopener noreferrer" className="dropdown-item">Google Docs Guide</a>
        </div>
      )}
    </div>
  )
}

export default DropdownMenu