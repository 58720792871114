import React from 'react'
import {linkHandler} from 'router'

import './index.scss'

export default () => null

export const Menu = () => (
  <div className="admin-menu">
    <ul>
      <li className="clickable" onClick={linkHandler('/manager/update')}>
      <i className="fa fa-sliders"/> <span>Update Devices</span>
      </li>
      <li className="clickable" onClick={linkHandler('/manager/alarmsSet')}>
      <i className="fa fa-tag"/> <span>Check Alarms Set</span>
      </li>
    </ul>
  </div>
)
