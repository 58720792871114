import { gql } from 'graphql.macro'
import React from 'react'
// import {route} from 'router'
import * as compose from 'lodash/flowRight'
import { graphql } from 'react-apollo'
import Toolbar2 from './Toolbar2'
import List from './List'
import Loading from 'Components/Loading'


export const GET_REBOOT = gql`
  query GET_REBOOT {
   me {
	id
	isSuperUser
	isManager
   }
	rebootDevices {
		id
		time
		gps_time
		count
	}
  }
`

const Reboot = ({data: {loading, error, me, rebootDevices}}) => {
	if (loading) return <Loading />
	if (error) return 'error'

	return (
		<div className="directory-body">
		<Toolbar2
			isSuperUser={me?.isSuperUser}
			isManager={me?.isManager}
		/>
		<List devices={rebootDevices} isSuperUser={me?.isSuperUser} type={'reboot'} on={'count'} direction={'desc'}/>
		</div>
  )
}

export default compose(  
   graphql(GET_REBOOT)
)(Reboot)
