import React, {useState} from 'react'
import {Query} from 'react-apollo'
import {GET_DEVICES_GHOSTS} from 'queries'
import Loading from 'Components/Loading'
import {route} from 'router'
import List from './List'

import './Alerts.scss'

const Ghosted = () => {
  // const [clearAlertWarning, setClearAlertWarning] = useState(false)
  const [previousDays, setPreviousDays] = useState(48)
  const [recentDays, setRecentDays] = useState(0)
  const [u_previousDays, setuPreviousDays] = useState(48)
  const [u_recentDays, setuRecentDays] = useState(0)

  function handleChange(e) {
    e.preventDefault()
    const name = e.target.name

    if (name === 'before') {
      setuPreviousDays(e.target.value)
    }

    if (name === 'after') {
      setuRecentDays(e.target.value)
    }
  }

  function submitForm(e) {
    e.preventDefault()
    setPreviousDays(parseInt(u_previousDays))
    setRecentDays(parseInt(u_recentDays))
  }

  return (
    <Query query={GET_DEVICES_GHOSTS} variables={{previousDays, recentDays}}>
      {({error, loading, data}) => {
        if (error) return 'error'
        if (loading) return <Loading />
        if (!!data && !data.me.isSuperUser) {
          route('/alarms')
        } 
        const alerts = (data.devicesGhosts || [])

          // special filter for via engineering devices
          // .filter((d) => d.company?.name !== "VIA ENGINEERING")
          // .filter((d) => d.path !== "/VIA ENGINEERING TEST")
          // .filter((d) => d.path !== "/VIA ENGINEERING COMPLETE")
          // .filter((d) => d.path !== "/VIA ENGINEERING TEST/Matt")


          return (
            <>{!!data.me.isSuperUser && (
            <div className='directory-body'>
              <div className='directory-top-bar'>
                <p className='title2344'>POSSIBLE GHOSTS VIEW</p>
                <form onSubmit={submitForm} className='formst'>
                <label className='setting-input'>Time Range (Hours):</label>
                <input className='input' name='after' value={u_recentDays} onChange={handleChange}/><span style={{paddingLeft: '10px'}}>-</span>
                <input className='input' name='before' value={u_previousDays} onChange={handleChange}/>
                <button type='submit' style={{marginLeft: '10px'}}>Submit</button>
                </form>
              </div>
            <List devices={alerts} isSuperUser={data && data.me && data.me.isSuperUser} on={'id'} direction={'asc'}/>
          </div>)}
          </>
          )
      }}
    </Query>
  )
}

export default Ghosted
