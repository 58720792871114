import {gql} from 'graphql.macro'

export const GET_DEVICES_GHOSTS = gql`
  query GET_DEVICES_GHOSTS($previousDays: Int, $recentDays: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesGhosts(previousDays: $previousDays, recentDays: $recentDays) {
      id
      userAssignedID
      name
      path
      isActive
      inService
      longitude
      latitude
      latestReading
      lastTimeMessageReceived
      batteryLevel
      displayUnits
      defaultTag
      companyID
      simNumber
      boardRev
      firmwareVersion
      modemType
      signalStrength
      company {
        name
      }
      tasks {
        deviceName
        companyName
        id
        deviceID
        priority
        isComplete
        notes
        type
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
      }
    }
  }
`

export const GET_DEVICES_SCRATCH = gql`
  query GET_DEVICES_SCRATCH($includeInactive: Boolean, $includeOOS: Boolean) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesScratch(includeInactive: $includeInactive, includeOOS: $includeOOS) {
      id
      userAssignedID
      name
      path
      isActive
      inService
      longitude
      latitude
      latestReading
      lastTimeMessageReceived
      batteryLevel
      displayUnits
      defaultTag
      iconFluid
      companyID
      simNumber
      boardRev
      firmwareVersion
      modemType
      signalStrength
      company {
        name
      }
      tasks {
        deviceName
        companyName
        id
        deviceID
        priority
        isComplete
        notes
        type
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
        calibration {
          fluid
        }
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
  }
`

export const GET_DEVICES_NEW = gql`
  query GET_DEVICES_NEW {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesNew {
      id
      name
      path
      isActive
      inService
      longitude
      latitude
      latestReading
      lastTimeMessageReceived
      batteryLevel
      displayUnits
      defaultTag
      companyID
      simNumber
      boardRev
      firmwareVersion
      modemType
      signalStrength
      company {
        name
      }
      tasks {
        deviceName
        companyName
        id
        deviceID
        priority
        isComplete
        notes
        type
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
        calibration {
          fluid
        }
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
  }
`

export const GET_DEVICES = gql`
  query GET_DEVICES($includeInactive: Boolean, $includeOOS: Boolean) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devices(includeInactive: $includeInactive, includeOOS: $includeOOS) {
      id
      name
      path
      isActive
      inService
      longitude
      latitude
      defaultTag
      boardRev
      modemType
      firmwareVersion
      sensorSubtype1
      sensorSubtype2
      tasks {
        deviceName
        companyName
        id
        deviceID
        priority
        isComplete
        notes
        type
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
        calibration {
          fluid
        }
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
      company {
        id
        name
      }
    }
  }
`

export const GET_PRUNED_DEVICES = gql`
  query GET_PRUNED_DEVICES($includeInactive: Boolean, $includeOOS: Boolean) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devices(includeInactive: $includeInactive, includeOOS: $includeOOS) {
      id
      name
      path
      isActive
      inService
      longitude
      latitude
      defaultTag
      boardRev
      firmwareVersion
      tasks {
        deviceName
        companyName
        id
        deviceID
        priority
        isComplete
        notes
        type
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        filterEnabled
        displayUnits
        calibration {
          fluid
        }
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
      company {
        id
        name
      }
    }
  }
`

export const GET_ME = gql`
  query GET_ME {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      countryCode
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
      }
    }
  }
`

export const GET_NOTIFICATION_SETTINGS = gql`
  query GET_NOTIFICATION_SETTINGS {
    me {
      id
      notificationSettings
    }
  }
`

export const GET_USERS = gql`
  query GET_USERS {
    me {
      id
      firstName
      lastName
      isManager
      isSuperUser
    }
    users {
      id
      firstName
      lastName
      phoneNumber
      countryCode
      email
      isManager
      isSuperUser
      isDemo
      canEdit
      company {
        id
        name
      }
      permissions
      latestActivity
    }
  }
`

export const GET_GROUPS = gql`
  query GET_GROUPS {
    me {
      id
      firstName
      lastName
      isManager
      isSuperUser
    }
    groups {
      id
      name
      company {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
      activeDevices {
        id
        name
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query GET_COMPANIES {
    companies {
      id
      name
      isActive
    }
  }
`
