import React, { useEffect, useState } from 'react'
import DeviceCard from 'Components/DeviceCard'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import Loading from 'Components/Loading'
import { gql } from 'graphql.macro'

const GET_DEVICES_NEW = gql`
  query GET_DEVICES_NEW($query: String, $limit: Int!, $offset: Int!) {
    devicesRe(query: $query, limit: $limit, offset: $offset) {
      id
      name
      path
      isActive
      inService
      latestReading
      lastTimeMessageReceived
      displayUnits
      defaultTag
      iconFluid
      tags {
        id
        deviceID
        tag
      }
    }
  }
`

const NewDevices = ({data: {loading, error, fetchMore, devicesRe}}) => {
  const [isEnd, setIsEnd] = useState(null)

    useEffect(() => {
      paginationHandler(20, fetchMore, devicesRe, setIsEnd)
    },[fetchMore, devicesRe])
  

  if (error) return 'error'
  if (loading) return <Loading />
  return (
    <div className="device-card-container">
      <div className="device-card-row">
        <div className="title">New Devices</div>
        {(devicesRe || [])
          .map(d => (
            <DeviceCard device={d} key={d.id} />
          ))}
      </div>
      {(!isEnd && devicesRe?.length > 0 ) && <h2 className='end'>loading more...</h2>}
    </div>
  )
}

const paginationHandler = (limit, fetchMore, data, setIsEnd) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        fetchMore({
          variables: { limit: limit, offset: data.length},
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult?.devicesRe?.length === 0) setIsEnd(true)
            return {
              devicesRe: [...prev.devicesRe, ...fetchMoreResult.devicesRe]
            }
          }
        })
        observer.unobserve(entry.target)
      }
    })

  const element = document.querySelector('.end')

  if(element) observer.observe(element)
}

export default compose(
  graphql(GET_DEVICES_NEW, {
    options: () => ({
      variables: {query: 'new', limit: 20, offset: 0}
    })
  })
)(NewDevices)
