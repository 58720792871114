import React, { useEffect, useState } from 'react'
// import DeviceCard from 'Components/DeviceCard'
import {graphql} from 'react-apollo'
import { gql } from 'graphql.macro'
import Loading from 'Components/Loading'

import * as compose from 'lodash/flowRight'

import './AllDevices.scss'
import Toolbar from './Toolbar2'
import List from './ListRe'
import {connect} from 'react-redux'
import {
  setDirectoryPath,
  setDirectoryCompany,
  toggleIncludeInactive,
  toggleIncludeOOS,
  setViewType,
  toggleIncludeVIA
} from 'store/actions'

export const GET_DEVICES_ALL = gql`
  query GET_DEVICES_ALL($includeInactive: Boolean, $includeOOS: Boolean, $query: String, $selectedCompanyID: Int, $limit: Int, $offset: Int, $sortBy: String, $sortDirection: String) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(includeInactive: $includeInactive, includeOOS: $includeOOS, query: $query, selectedCompanyID: $selectedCompanyID, limit: $limit, offset: $offset, sortBy: $sortBy, sortDirection: $sortDirection) {
      id
      name
      path
      userAssignedID
      batteryLevel
      displayUnits
      longitude
      latitude
      sensorSubtype1
      sensorSubtype2
      firmwareVersion
      modemType
      simNumber
      boardRev
      signalStrength
      defaultTag
      companyID
      company {
        name
      }
      latestReading
      lastTimeMessageReceived
      tags {
        tag
        alarms {
          flag
        }
      }
    }
  }
`

const AllDevices = ({data: {loading, error, refetch, fetchMore, me, devicesRe}}) => {
  const [sortBy, setSortBy] = useState('id')
  const [sortDirection, setSortOrder] = useState('asc')
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    paginationHandler(50, fetchMore, devicesRe, sortBy, sortDirection, setIsEnd)
  },[fetchMore, devicesRe, sortBy, sortDirection])
  
  const handleSort = (field) => {
    let order
    order = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(field)
    setSortOrder(order)

    setIsEnd(false)
    refetch({limit: 50, offset: 0, sortBy: field, sortDirection: order})
  }

  if (error) return 'error'
  if (loading) return <Loading />

  const devices = devicesRe
  return (
    <div className="directory-body">
        <Toolbar
          isSuperUser={me?.isSuperUser}
          isManager={me?.isManager}
        />
        <List devices={devices} isSuperUser={me?.isSuperUser} sorter={handleSort} on={sortBy} direction={sortDirection} isEnd={isEnd} type={'all'}/>
    </div>
  )
}

const paginationHandler = (limit, fetchMore, data, sortBy, sortDirection, setIsEnd) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        fetchMore({
          variables: { limit: limit, offset: data.length, sortBy, sortDirection},
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult?.devicesRe?.length === 0) setIsEnd(true)
            return {
              me: prev.me,
              devicesRe: [...prev.devicesRe, ...fetchMoreResult.devicesRe]
            }
          }
        })
        observer.unobserve(entry.target)
      }
    })

  const element = document.querySelector('.end')

  if(element) observer.observe(element)
}

const mapStateToProps = (state) => ({
    path: state.directory.path,
    companyID: state.directory.companyID,
    includeInactive: state.directory.includeInactive,
    includeOOS: state.directory.includeOOS,
    includeVIA: state.directory.includeVIA,
    viewType: state.directory.viewType
  })
  const mapDispatchToProps = (dispatch) => ({
    setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
    setDirectoryCompany: (id) => {
      dispatch(setDirectoryPath(''))
      dispatch(setDirectoryCompany(id))
    },
    toggleIncludeInactive: () => dispatch(toggleIncludeInactive()),
    toggleIncludeOOS: () => dispatch(toggleIncludeOOS()),
    toggleIncludeVIA: () => dispatch(toggleIncludeVIA()),
    setViewType: (type) => dispatch(setViewType(type))
  })
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(GET_DEVICES_ALL, {
      options: (props) => ({
        variables: {query: 'all', limit: 50, offset: 0, sortBy: 'id', sortDirection: 'asc', includeInactive: props.includeInactive, includeOOS: props.includeOOS, includeVIA: props.includeVIA}
      })
    })
  )(AllDevices)