import React from 'react'
import './NotificationCard.scss'
import formatEpochTime from 'utils/formatEpochTime'


const NotificationCard = ({notification, openModal}) => {
  const getIcon = (category) => {
    switch (category) {
      case "critical":
        return (
          <i className="fas fa-exclamation-circle icon"></i>
        );
      case "warning":
        return (
          <i className="fas fa-exclamation-triangle icon"></i>
        );
      case "success":
        return <i className="fas fa-check-circle icon"></i>;
      case "info":
        return <i className="fas fa-info-circle icon"></i>;
      case "update":
        return <i className="fas fa-cloud-arrow-up icon"></i>;
      default:
        return null;
    }
  }

  return (
    <li
      className={` notification-item ${notification.notice_type}
        `}
      onClick={() => openModal(notification)}
    >
      {getIcon(notification.notice_type)}
      <span>
        <strong>{notification.title}</strong><br />
        {formatEpochTime(notification.created_at)}
      </span>

    </li>
  )
}
export default NotificationCard
