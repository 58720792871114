import React from 'react'
import ago from 'utils/ago'
import Loadable from 'react-loadable'
import Loading from 'Components/Loading'

import './List.scss'
import { isNumber } from 'lodash'
const GoogleMap = new Loadable({
  loader: () => import('./ListMap'),
  loading: Loading
})


class List extends React.Component {
  state = {
    sortBy: this.props.on,
    sortDirection: this.props.direction,
    menu: {
      type: false
    },
    modal: false,
    alatitude: 0,
    alongitude: 0
  }
  sort = (key) => {
    this.props.sorter(key)
  }
  renderTree = (tag, subtype1, subtype2) => {
    let tagString = tag
    let subString1 = subtype1
    let subString2 = subtype2
    switch (tag) {
      case 'spsr':
        tagString = 'Tank Level'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'dspsr':
        tagString = 'Tank Level - Dual Sensor'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'psr':
        tagString = 'Pressure'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Normal'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'puls':
        tagString = 'Turbine'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'rot':
        tagString = 'Beam Pump'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          default:
            subString1 = 'Dongle - Not Reported'
        }
        subString2 = 'na'
        break
      case 'temp':
        tagString = 'Temperature'
        switch (subtype1) {
          case 1:
            subString1 = 'Skin'
            break
          case 2:
            subString1 = 'Insert'
            break
          default:
            subString1 = 'Not Reported'
        }
        switch (subtype2) {
          case 1:
            subString2 = 'Bare Wire'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'vib':
        tagString = 'Vibration'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          case 3:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'dist':
        tagString = 'Radar'
        switch (subtype1) {
          case 1:
            subString1 = 'Internal'
            break
          case 2:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'hum':
        tagString = 'Humidity'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'sonr':
        tagString = 'Sonar'
        subString1 = 'na'
        subString2 = 'na'
        break
      default:
        tagString = 'na'
        subString1 = 'na'
        subString2 = 'na'
        break
    }
    return {tagString, subString1, subString2}
  }
  sortIndicator = (key) =>
    this.state.sortBy === key ? (
      this.state.sortDirection === 'asc' ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      )
    ) : null
  render() {
    var devices = []
    devices = this.props.devices
      .map(
        ({
          id,
          userAssignedID,
          name,
          path,
          lastTimeMessageReceived,
          latestReading,
          batteryLevel,
          company,
          displayUnits,
          defaultTag,
          longitude,
          latitude,
          sensorSubtype1,
          sensorSubtype2,
          firmwareVersion,
          modemType,
          simNumber,
          boardRev,
          signalStrength,
          tmobileOp,
          verizonOp,
          attOp,
          unknownOp
        }) => {
          const tree = this.renderTree(
            defaultTag,
            sensorSubtype1,
            sensorSubtype2
          )
          
          const defaultTagHumanReadable = tree.tagString
          sensorSubtype1 = tree.subString1
          sensorSubtype2 = tree.subString2
          
          return {
            id,
            userAssignedID,
            name,
            companyName: company && company.name,
            path,
            lastTimeMessageReceived,
            latestReading,
            batteryLevel,
            displayUnits,
            defaultTag,
            longitude,
            latitude,
            defaultTagHumanReadable,
            sensorSubtype1,
            sensorSubtype2,
            firmwareVersion,
            modemType,
            simNumber,
            boardRev,
            signalStrength,
            tmobileOp,
            verizonOp,
            attOp,
            unknownOp
          }
        }
      )

    if (this.props.type === 'cell') {
      return (
        <div className="list-container device-list">
          <div className="scrollable-container">
            <table>
              <thead>
                <tr>
                <th className="id clickable" onClick={() => this.sort('id')}>
                  ID {this.sortIndicator('id')}
                </th>
                <th className="name clickable" onClick={() => this.sort('name')}>
                  Name {this.sortIndicator('name')}
                </th>
                <th className="last-message clickable" onClick={() => this.sort('companyName')}>
                  Company {this.sortIndicator('companyName')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('coordsla')}
                >
                  Coords {this.sortIndicator('coordsla')}
                </th>
                <th className="last-message clickable" onClick={() => this.sort('time')}>
                  Last Msg. {this.sortIndicator('time')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('firmwareVersion')}
                >
                  Firmware {this.sortIndicator('firmwareVersion')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('signalStrength')}
                >
                  Signal {this.sortIndicator('signalStrength')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('tmobile')}
                >
                  T-Mobile {this.sortIndicator('tmobile')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('att')}
                >
                  AT&T {this.sortIndicator('att')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('verizon')}
                >
                  Verizon {this.sortIndicator('verizon')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('unknown')}
                >
                  Unknown {this.sortIndicator('unknown')}
                </th>
              </tr>
              </thead>
              <tbody>
                {devices.map((d) => (
                  <tr key={d.id} className="row" style={{height: 30}}>
                    <td className="id" style={{height: 'auto'}}>
                      <a href={`#/devices/${d.id}`}>{d.id}</a>
                    </td>
                    <td
                      className="name"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      <a href={`#/devices/${d.id}`}>
                        {d.path ? d.path + ' : ' : !d.name ? <i>N/A</i> : ''} <strong>{d.name ? d.name : !d.path ? '' :'[No Name]'}</strong>
                      </a>
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.companyName || <i>N/A</i>}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {isNumber(d.longitude) &&
                      isNumber(d.latitude) ? (
                        <span
                          className={'link'}
                          onClick={() => this.setState({modal: 'showMap', alatitude: d.latitude, alongitude: d.longitude})}
                        >
                          {`${d.latitude.toFixed(
                            7
                          )}, ${d.longitude.toFixed(7)}`}
                        </span>
                      ) : (
                        <span
                        >
                          N/A
                        </span>
                      )}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      <div className={d.isDelayed ? 'warning' : 'normal'}>
                        {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                      </div>
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.firmwareVersion || <i>N/A</i>}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.signalStrength || <i>N/A</i>}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em', color: d.tmobileOp ? 'green' : 'red'}}
                    >
                      {d.tmobileOp ? 'Yes' : 'No'}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em', color: d.attOp ? 'green' : 'red'}}
                    >
                      {d.attOp ? 'Yes' : 'No'}
                    </td>
                    <td
                      className={"last-message"}
                      style={{height: 'auto', minHeight: '1.1em', color: d.verizonOp ? 'green' : 'red'}}
                    >
                      {d.verizonOp ? 'Yes' : 'No'}
                    </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em', color: d.unknownOp ? 'green' : 'red'}}
                    >
                      {d.unknownOp ? 'Yes' : 'No'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {devices?.length === 0 ? <h1 className="empty-text">No Devices Found</h1> : !this.props.isEnd && <h2 className='end'>loading more...</h2>}
          </div>
          {this.state.modal === 'showMap' && (
          <GoogleMap
            latitude={this.state.alatitude}
            longitude={this.state.alongitude}
            onClose={() => this.setState({modal: false})}
          />
          )}
        </div>
      )
    }

    return (
      <div className="list-container device-list">
        <div className="scrollable-container">
          <table>
            <thead>
              <tr>
              <th className="id clickable" onClick={() => this.sort('id')}>
                ID {this.sortIndicator('id')}
              </th>
              {(!this.props.isSuperUser && (this.props.type !== 'cell')) && (
              <th className="id clickable" onClick={() => this.sort('uaid')}>
                UA ID {this.sortIndicator('uaid')}
              </th>
              )}
              <th className="name clickable" onClick={() => this.sort('name')}>
                Name {this.sortIndicator('name')}
              </th>
              <th className="last-message clickable" onClick={() => this.sort('companyName')}>
                Company {this.sortIndicator('companyName')}
              </th>
              <th
                className="last-message clickable"
                onClick={() => this.sort('defaultTagHumanReadable')}
              >
                Sensor Type {this.sortIndicator('defaultTagHumanReadable')}
              </th>
              {!this.props.isSuperUser && (
              <>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('units')}
                >
                  Units {this.sortIndicator('units')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('coordsla')}
                >
                  Coords {this.sortIndicator('coordsla')}
                </th>
                <th
                  className="last-message clickable"
                  onClick={() => this.sort('reading')}
                >
                  Last Reading {this.sortIndicator('reading')}
                </th>
              </>
              )}
              <th className="last-message clickable" onClick={() => this.sort('time')}>
                Last Msg. {this.sortIndicator('time')}
              </th>
              <th className="voltage clickable" onClick={() => this.sort('battery')}>
                Battery {this.sortIndicator('battery')}
              </th>
              <th
                className="last-message clickable"
                onClick={() => this.sort('firmwareVersion')}
              >
                Firmware {this.sortIndicator('firmwareVersion')}
              </th>
              {this.props.isSuperUser && (
                <>
                  <th
                    className="last-message clickable"
                    onClick={() => this.sort('signalStrength')}
                  >
                    Signal {this.sortIndicator('signalStrength')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={() => this.sort('modemType')}
                  >
                    Modem {this.sortIndicator('modemType')}
                  </th>
                  <th
                    className="sim-number clickable"
                    onClick={() => this.sort('simNumber')}
                  >
                    SIM Number {this.sortIndicator('simNumber')}
                  </th>
                  <th
                    className="last-message clickable"
                    onClick={() => this.sort('boardRev')}
                  >
                    Board {this.sortIndicator('boardRev')}
                  </th>
                </>
              )}
            </tr>
            </thead>
            <tbody>
              {devices.map((d) => (
                <tr key={d.id} className="row" style={{height: 30}}>
                  <td className="id" style={{height: 'auto'}}>
                    <a href={`#/devices/${d.id}`}>{d.id}</a>
                  </td>
                  {!this.props.isSuperUser && (
                  <td
                    className="id"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.userAssignedID? d.userAssignedID : <i>N/A</i>}
                  </td>
                  )}
                  <td
                    className="name"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <a href={`#/devices/${d.id}`}>
                      {d.path ? d.path + ' : ' : !d.name ? <i>N/A</i> : ''} <strong>{d.name ? d.name : !d.path ? '' :'[No Name]'}</strong>
                    </a>
                  </td>
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.companyName || <i>N/A</i>}
                  </td>
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.defaultTagHumanReadable && d.defaultTagHumanReadable !== 'na' ?  d.defaultTagHumanReadable : <i>N/A</i>}
                  </td>
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.displayUnits ? d.displayUnits : 'default'}
                  </td>
                  )}
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {(d.latitude && d.longitude) ? `${d.latitude}, ${d.longitude}` : <i>N/A</i>}
                  </td>
                  )}
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.latestReading ? d.latestReading : <i>N/A</i>}
                  </td>
                  )}
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <div className={d.isDelayed ? 'warning' : 'normal'}>
                      {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : <i>N/A</i>}
                    </div>
                  </td>
                  <td
                    className="voltage"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                      {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : <i>N/A</i>}
                    </div>
                  </td>
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.firmwareVersion || <i>N/A</i>}
                    </td>
                  {this.props.isSuperUser && (
                    <>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                        {d.signalStrength || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                      {d.modemType || <i>N/A</i>}
                      </td>
                      <td
                        className="sim-number"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                      {d.simNumber || <i>N/A</i>}
                      </td>
                      <td
                        className="last-message"
                        style={{height: 'auto', minHeight: '1.1em'}}
                      >
                      {d.boardRev || <i>N/A</i>}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {devices?.length === 0 ? <h1 className="empty-text">No Devices Found</h1> : !this.props.isEnd && <h2 className='end'>loading more...</h2>}
        </div>
      </div>
    )
  }
}

export default List
